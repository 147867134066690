export const UserSignUpStatuses = {
    "success": {
        type: "success",
        title: "We'd like to verify your email account first",
        content:
            "We’re almost there! Kindly check your e-mail (**%user-email%**) to get the activation link. If you did not get the e-mail within a few minutes, check your spam folder.",
        allowReload: false,
        allowResend: true,
    },
    "completed": {
        type: "success",
        title: "Verification has been completed.",
        content: "You may now proceed to login to the SeekCap Website.",
    },
    "reverify": {
        type: "reverify",
        title: "We'd like to verify your email account first",
        content:
            "We’re almost there! Kindly check your e-mail (**%user-email%**) to get the activation link. If you did not get the e-mail within a few minutes, check your spam folder.",
        allowReload: false,
        allowResend: true,
    },
    "verifying": {
        type: "busy",
        title: "We are verifying your link",
        content: "Please click the checkbox below to continue to verify your account",
        allowReload: true,
    },
    "verified": {
        type: "verified",
        title: "Your account has been verified!",
        content: "We will redirect you to the marketplace in a few seconds.",
    },
    "verify-error": {
        type: "error",
        title: "Verification error",
        content: "There has been an error in the verification process.",
    },
    "expired": {
        type: "error",
        title: "Your verification link has expired, check your email to verify your email",
        content:
            "We sent you another link on your email (**%user-email%**). Kindly check your e-mail to get the activation link. If you did not get the e-mail within a few minutes, check your spam folder.",
        allowResend: true,
    },
    "error": {
        type: "error",
        title: "Something went wrong",
        content: "An invalid status has been detected.",
    },
};

export const UserCampaignDataFields = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

export const UserCookieVarSignUpEmail = "_signup-email";
export const UserCookieVar = "user";
export const UserCookieVarId = "user_id";
export const UserCookieVarProfile = "profile";
export const UserCookieVarProfileFeedback = "profile_feedback";
export const UserCookieVarProfileAdditionalInfo = "profile_additional_info";
export const UserCookieVarConsent = "_consent";
export const UserCookieVarProfilePhoto = "avatar";
export const UserCookieVarEKYCViewed = "_ekyc-viewed";

export const UserVarCampaignData = "campaign-data";
export const UserCookieReferralCode = "referral_code";
