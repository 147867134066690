import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import App from "./App";
// import * as serviceWorker from './serviceWorker';

import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/animate.css";
import "./assets/css/ubx.css";
import "./assets/css/layout.css";
import "./assets/css/styles.css";

import FeatureFlagClient from "./featureFlagsClient.js";

import AxiosInterceptor from "./axiosInterceptor.js";

const FeatureFlags = FeatureFlagClient();

AxiosInterceptor();

// ReactDOM.render(<App />, document.getElementById("root"));

const rootElement = document.getElementById("root");

if (
    rootElement.hasChildNodes() &&
    !(rootElement.childNodes.length === 1 && rootElement.childNodes[0].nodeName === "#text")
) {
    ReactDOM.hydrate(<App extensions={{ FeatureFlags }} />, rootElement);
} else {
    ReactDOM.render(<App extensions={{ FeatureFlags }} />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
