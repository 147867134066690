// Props Helpers
export function getReferer(componentProps) {
    const {
        location: { state = false },
    } = componentProps;
    let { referer = false } = state || {};

    if (!referer) {
        referer = window.location.href.includes(window.document.referrer) === false ? window.document.referrer : false;
    }

    return referer;
}

export function getPathParams(componentProps, pathName = false) {
    if (componentProps && componentProps.match) {
        const { params, path } = componentProps.match;
        params["__path"] = path;
        return pathName ? params[pathName] : params;
    }
}

export function getLocationState(componentProps, stateName = false) {
    if (componentProps && componentProps.location) {
        const { state = {} } = componentProps.location;
        return stateName ? state[stateName] : state;
    }
}

export function getLocationPath(componentProps) {
    if (componentProps && componentProps.location) {
        const { pathname } = componentProps.location;
        return pathname;
    }
}

export function getQueryString(componentProps, param = false) {
    const search = componentProps?.location?.search || "";

    let queryStringOutput = {};
    if (search !== "") {
        const paramItems = new URLSearchParams(search);

        for (const paramItem of paramItems) {
            const [param, value] = paramItem;

            if (/\[\]$/.test(param)) {
                const paramArrayName = param.replace(/\[\]$/, "");

                if (!queryStringOutput[paramArrayName]) {
                    queryStringOutput[paramArrayName] = [];
                }
                queryStringOutput[paramArrayName].push(value);
            } else {
                queryStringOutput[param] = value;
            }
        }
    }

    return param ? queryStringOutput[param] : queryStringOutput;
}
