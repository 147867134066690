import { COLLECT_DATA, RESET_DATA } from "./../actions/actionTypes";

let initialState = {
    // Personal Info -----------------------------------------------------------
    personFirstName: "", // First Name
    personLastName: "", // Last Name
    personMiddleName: "", // Middle Name
    personBirthdate: "", // Birthdate
    personGenderD: "", // Sex
    personBirthPlace: "", // Birthplace
    personNationality: "", // Nationality
    companyEmpDesig: "", // Occupation
    companyEmpDesigO: "", // Occupation (if others)
    personMobileNo: "", // Mobile Number
    personCivilStatus: "", // Civil Status
    personMonthlyIncome: "", // Monthly Income
    personTaxReference: "", // TIN
    personGSISID: "", // GSIS id
    personSSSID: "", // SSS Id
    addressLine1: "", // Address - Unit Number
    addressStreet: "", // Address - Street
    addressSubdistrict: "", // Address - Subdivision/Village
    addressProvince: "", // Address - Province
    addressCity: "", // Address - City
    personSpouseFirstName: "", // Spouse First Name
    personSpouseLastName: "", // Spouse Last Name
    personSpouseMiddleName: "", // Spouse Middle Name
    personSpouseBirthdate: "", // Spouse Birthdate
    companySpouseMobileNo: "", // Spouse Mobile Number
    companySpouseDesig: "", // Spouse Occupation
    companySpouseDesigO: "", // Spouse Occupation (if others)
    personDependent: "", // Number of Dependents
    personEmail: "", // Email Address
    personFullNameID: "", // Full Name

    // Business Details --------------------------------------------------------
    companyName: "", // Legal name of company
    companyNature: "", // Industry
    companyBizPeriod: "", // Years in Operation
    companyType: "", // Business Type
    companyTaxReference: "", // TIN
    companyRegDt: "", // Date Incorporated
    companyDesignation: "", // Position
    companyEmail: "", // Business Email
    companyTelNo: "", // Business Contact
    companySuppName: "", // Main Supplier
    companyPriBank: "", // Main Bank
    companyAnnualRevenue: "", // Annual Rev
    companyAssetSize: "", // Asset Size
    companyECommChannel: "", // Ecommerce Channel
    companyECommChannelID: "", // Ecommerce Channel ID
    companyEWallet: "", // EWallet
    companyEWalletID: "", // EWallet ID
    companyEmpCount: "", // Employee Size
    companyOSDebt: "", // Outstanding Debt
    companyOfcMobileNo: "", // Office Mobile Number

    // Business Address --------------------------------------------------------
    companyLine1: "", // Unit No./Building
    companyStreet: "", // Street
    companyBarangay: "", // Barangay
    companyPostal: "", // Zip Code
    companyEstTyp: "", // Type of establishment
    companyCountry: "", // Country
    companyProvince: "", // Province
    companyCity: "", // City
    companyAddrRegion: "", // Region
    companyBizPeriodCurrAddr: "", // Yrs in Current Address

    // Business Documents ------------------------------------------------------
    attchDTISECCert: "",
    attchBizPermit: "",
    attchSalesProof: "",
    attchBillingProof: "",
    attchBizPlcPhotoInt: "",
    attchBizPlcPhotoExt: "",
    attchImgId: "",
    attchImgSelfie: "",
    attchISP: "",
};

const applicationFormData = (state = initialState, action) => {
    switch (action.type) {
        case COLLECT_DATA: {
            return Object.assign({}, state, action.payload);
        }
        case RESET_DATA: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default applicationFormData;
