import axios from "axios";

import Cookies from "react-cookies";

import { UserCookieVar, UserCookieVarId, UserCookieVarProfile } from "./const/user";

const axiosInterceptor = () => {
    axios.interceptors.request.use(
        (config) => {
            const user = Cookies.load(UserCookieVar) || {};

            const accessToken = user["access-token"] || null;

            if (accessToken) {
                config.headers.Authorization = accessToken;
            }

            return config;
        },
        (error) => Promise.reject(error),
    );

    axios.interceptors.response.use(
        function (response) {
            // console.log("Non Error", { response });
            return response;
        },
        async (error) => {
            const { message, response } = error;

            if (message === "Network Error") {
                error["response"] = {
                    data: {
                        status: "FAILED",
                        data: {
                            message: "Network Error occurred",
                        },
                    },
                };
            } else if (response) {
                const { status, config, statusText, data: responseData } = response;
                const prevConfig = { ...config };

                if (status === 401) {
                    const user = Cookies.load(UserCookieVar);

                    if (!user) {
                        window.location.href = "/";
                    } else {
                        if (!config.headers.retried) {
                            try {
                                const response = await _refreshAccessToken(user["refresh-token"]);
                                const { AccessToken: Authorization = null, ExpiresIn: maxAge = 0 } =
                                    response.data.data.AuthenticationResult || {};

                                prevConfig.headers = {
                                    ...config.headers,
                                    Authorization,
                                    retried: true,
                                };

                                user["access-token"] = Authorization;

                                Cookies.save(UserCookieVar, user, { maxAge, path: "/" });
                                Cookies.save(UserCookieVarId, user["user-id"], { maxAge, path: "/" });

                                return axios.request(prevConfig);
                            } catch (error) {
                                Cookies.save(UserCookieVar, "", { maxAge: 0, path: "/" });
                                Cookies.save(UserCookieVarId, "", { maxAge: 0, path: "/" });
                                Cookies.save(UserCookieVarProfile, "", { maxAge: 0, path: "/" });

                                window.location.reload();
                            }
                        } else {
                            Cookies.setRawCookie("");
                        }
                    }
                }

                if (!responseData || Object.keys(responseData).length === 0) {
                    response["data"] = {
                        status,
                        data: {
                            message: statusText,
                        },
                    };
                    error["response"] = response;
                }
            } else {
                error["response"] = {
                    data: {
                        status: "FAILED",
                        data: {
                            message: "Something went wrong",
                        },
                    },
                };
            }

            return Promise.reject(error);
        },
    );
};

export const _refreshAxios = axios.create({});
const _refreshAccessToken = async (refreshToken) => {
    return await _refreshAxios.post(`${process.env.REACT_APP_API_ENDPOINT}/2/borrower/token/management/`, null, {
        headers: { Authorization: refreshToken },
    });
};

export default axiosInterceptor;
