export const MarketingCampaigns = {
    "ubp_smecampaign092020": "/products?lender=UBP001",
    "lazada_coop": "/products?lender=UBP001",
    "lazada_coop_inbound": "/products?lender=UBP001",
    "UBP_SME_Campaign": "/products?lender=UBP001",
    "foodpanda_inbound": "/products?lender=UBP001%2CEF001",
    "nudge_terminatedoffers_11192020": "/products?lender=UBP001",
    "lazada_edm3": "/products?lender=UBP001",
    "ubpsmeapp_inbound": "/products?lender=UBP001",
    "seekcap_ubpbanner": "/products?lender=UBP001",
    "mosaicedm": "/products?lender=UBP001",
};

export const ReferralCampaigns = {
    "/henyo/:referralCode": "/?utm_source=referral&utm_medium=referral&utm_campaign=[referralCode]",
};

export const VanityLinks = {
    "/businessloan": {
        "development": "/products/83df7a82-6cc0-478d-917a-2822422d4313",
        "staging": "/products/f11afd97-0e5f-4335-80e0-1c1997f524e0",
        "production": "/products/db3bdacf-0ca3-43de-b3ab-28d24af8c5c1",
    },
    "/foodpanda": "/products/foodpanda?&utm_source=affiliate&utm_medium=referral&utm_campaign=foodpanda",
    "/1MExclusive": "/products?&utm_source=ubp_edm&utm_medium=email&utm_campaign=ubploan_1m&special_product=ubploan_1m",
    "/300KExclusive":
        "/products?&utm_source=ubp_edm&utm_medium=email&utm_campaign=ubp_loanproducts&special_product=ubp_loanproducts",
    "/womenbizph": "https://campaign.seekcap.ph/womenbizph/",
    "/ubsmeciti": "/products?&utm_source=ubp_edm&utm_medium=email&utm_campaign=citibank&special_product=citibank",
    "/ubpleadgen": "/products/unionbank?&utm_source=affiliate&utm_medium=referral&utm_campaign=ubpleadgen",
    "/products/ubp-visa":
        "https://apply.cc-pl.unionbankph.com/PLCC/Prequalification_PrincipalCC?scode=XCPAUBXBAU&pcode=VP201&media=XSUXMPHCCAENWB&utm_source=xsell&utm_medium=web&utm_campaign=XCPAUBXBAU",
};

export const MarketingPromos = [
    {
        inlink: "/products/juanhand?amount=5000&term=1",
        // banner: "/marketing/JH-x-SeekCap-Promo-Ad.webp",
        banner: "/marketing/JH-x-SeekCap-Promo-Ad-Banner(desktop).webp",
        bannerMobile: "/marketing/JH-x-SeekCap-Promo-Ad-Banner(mobile).webp",
        title: "JuanHand x SeekCap 2023 Promo",
        start: "2023-02-15 00:10:00",
        end: "2023-03-31 23:59:59",
    },
];

export const MarketingPromoStorageVarName = "promo-seen";
export const MarketingPromoStorageMaxAge = 1800;
export const MarketingPromoStorage = window.sessionStorage;
