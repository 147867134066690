export const SystemURL = {
    "development": "https://app-dev.sme.ubx.ph",
    "staging": "https://app-stg.sme.ubx.ph",
    "production": "https://www.seekcap.ph",
};

export const PagePaths = {
    "HomePage": "/",
    "Dashboard": ["/dashboard", "/dashboard/:page"],
    // "UserPreQual": ["/pre-qualification", "/pre-qualification/:page", "/pre-qualification/:page/:sub_page"],
    "UserPreQual": ["/eligibility", "/eligibility/:page", "/eligibility/:page/:sub_page"],
    "UserLogin": "/login",
    "UserLogout": "/logout",
    "UserSignUp": "/sign-up",
    "UserSignUpCampaign": "/campaign-sign-up",
    "UserSignUpUpdate": "/sign-up/update",
    "UserSignUpStatus": "/sign-up/:status",
    "UserVerify": "/verify/:code/:email",
    "UserPasswordReset": "/password-reset/:code/:email",
    "UserConsent": "/user-consent/:code/:email",
    "Products": "/products",
    "Product": "/products/:product_id",
    "Profile": ["/profile", "/profile/:section", "/profile/:section/:sub_section"],
    "LoanApplication": [
        "/products/:product_id/apply/:term/:amount",
        "/loans/:loan_id/draft",
        "/loans/:loan_id/draft/:status",
        "/loans/:loan_id/:status",

        // motorcycle related
        "/motorcycles/select/:motorcycle_id/apply/:product_id/:term/:amount",
        "/motorcycles/select/:motorcycle_id/apply/:product_id/:term/:amount/:dealer",
    ],
    "LoanApplicationForm": [
        "/loans/:loan_id/draft/edit",
        "/loans/:loan_id/draft/edit/:section",
        "/loans/:loan_id/draft/edit/:section/:sub_section",
        "/loans/:loan_id/resubmit",
        "/loans/:loan_id/resubmit/:section",
        "/loans/:loan_id/resubmit/:section/:sub_section",
    ],
    "LoanView": ["/loans/:loan_id", "/loans/:loan_id/form-summary"],
    "LoanDisbursement": ["/loans/:loan_id/disbursement", "/loans/:loan_id/disbursement/:step"],
    "LoanAcceptance": [
        "/loans/:loan_id/acceptance",
        "/loans/:loan_id/acceptance/:page",
        "/loans/:loan_id/acceptance/:page/:status",
    ],
    // "LoanCalculator": "/loan-calculator",
    "ExternalCallback": "/callback/:source",
    "PartnersPage": "/partners",
    "CalculatorPage": "/calculator",
    "NewsPage": "/news",
    "Testimonials": "/testimonials",
    "ReferralPage": "/henyo/:referralCode",
    "MGMPage": "/the-henyo-bayanihan.html",
    "TermsPage": "/terms-and-conditions.html",
    "PrivacyPage": "/privacy-policy.html",
    "AboutPage": "/about.html",
    "FAQPage": "/faq.html",
    "NotFound": "/not-found.html",
    "Error500": "/500.html",
    "Redirect": "/redirect",
    // motorcycles related
    "MotorcyclesHomePage": "/motorcycles",
    "MotorcyclesProducts": "/motorcycles/select",
    "MotorcyclesProduct": "/motorcycles/select/:motorcycle_id",
    "MotorcyclesDashboard": ["/motorcycles/dashboard", "/motorcycles/dashboard/:page"],
};

export const PageRedirects = {
    // "/draft/:id/:pagename": "/loans/:id/draft/edit",
    "/loans/:id/loan-history": "/loans/:id/",
    "/loans/:id/re-upload": "/loans/:id/resubmit",
    "/draft/:id": "/loans/:id/draft/edit",
    "/loans": "/dashboard/applications",
    "/terms-and-condition.html": "/terms-and-conditions.html",
    // "/terms-and-condition.html": "/terms-and-conditions.html",
    "/pre-qualification": "/eligibility",
    "/pre-qualification/:page": "/eligibility/:page",
    "/pre-qualification/:page/:sub_page": "/eligibility/:page/:sub_page",
};

// export const NewsFeed = "/news.rss";
// export const NewsFeed = "https://www.ubx.ph/tag/seekcap/feed/?paged=";
// export const NewsArticle = "https://www.ubx.ph/?p=:article_id";
export const FBPage = "https://www.facebook.com/SeekCapPH";
export const IGPage = "https://www.instagram.com/seekcap.ph/";
export const LNPage = "https://www.linkedin.com/showcase/seekcap/";
export const YTPage = "https://www.youtube.com/@SeekCapPH";
export const TTPage = "https://www.tiktok.com/@seekcap";
export const FAQWebsite = "https://faq.seekcap.ph";
export const BlogWebsite = "https://campaign.seekcap.ph/blog";
export const SupportEmailAddress = "support@seekcap.ph";
export const LoanUpdateEmailAddress = "support@seekcap.ph";

export const ConsentVarHome = "home-dashboard_notice";
export const ConsentVarHomeExpiry = 86400;

export const SignUpResendTimer = 30;
export const SignUpResendSuccessRestart = 15;
export const SignUpResendErrorRestart = 10;
export const SignUpVerifiedTimer = 5;

export const SignUpMethods = {
    "facebook": {
        icon: "fb",
        title: "Coming Soon!",
        text: "Signup with Facebook",
        disabled: true,
    },
    "google": {
        icon: "google",
        title: "Signup with Google",
        text: "Signup with Google",
    },
    "default": {
        icon: "mail",
        title: "Signup with Email",
        text: "Signup with Email",
    },
};

export const LoginMethods = {
    "facebook": {
        icon: "fb",
        title: "Login with Facebook",
        text: "Login with Facebook",
        disabled: true,
    },
    "google": {
        icon: "google",
        title: "Login with Google",
        text: "Login with Google",
        disabled: true,
    },
};

export const RegExValidation = {
    NAME: "^[A-Za-z][A-Za-z ]*[A-Za-z]$",
    TEXT: "^[A-Za-z0-9 ]+$",
    NOSPACETEXT: "^[A-Za-z0-9]+$",
};
